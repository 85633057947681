
import { graphql } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import {GatsbyImage, getImage} from "gatsby-plugin-image"

export const fragment = graphql`
  fragment BannerImageFragment on WpPage_Flexlayouts_FlexibleLayouts_BannerImage {  
    image {
        altText
        sourceUrl
        localFile {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP, AVIF]
              width: 1280
              aspectRatio: 1.78
              quality: 75
              placeholder: NONE
            )
          }
        }
     }
  }
`

const BannerImage = ({image}) => {
  if(!image) {
    return null
  }
  return (
      <section>
        <Container className="d-flex justify-content-center my-5">
          <GatsbyImage
              image={getImage(image.localFile)}
              alt={image.altText ? image.altText : "banner"}
          />
        </Container>
      </section>

  )
}

export default BannerImage

